import React from "react";
import styles from "./AboutCompany.module.scss";
import { motion } from 'framer-motion'
import { useTranslation } from "react-i18next";

export default function AboutCompany() {
  const { t } = useTranslation();

  const contactVariants = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 }
}
const contactVariantsMinus = {
    initial: { opacity: 0, y: -80 },
    animate: { opacity: 1, y: 0 }
}
const contactVariantsX = {
    initial: { opacity: 0, x: 125 },
    animate: { opacity: 1, x: 0 }
}
const contactVariantsMinusX = {
    initial: { opacity: 0, x: -125 },
    animate: { opacity: 1, x: 0 }
}
  return (
    <div className={styles.page}>
      <motion.div className={styles.header}
      initial={'initial'}
      whileInView={'animate'}
      transition={{ duration: 0.7 }}
      variants={contactVariants}>
        <h1>{t("About Company")}</h1>
        <p>
          {t(
            "Absolutely new technology. Our artificial intelligence software analyzes the market and opens trading positions faster and more efficiently than 88% of global traders."
          )}
        </p>
      </motion.div>
      <div className={styles.info}>
        <motion.div className={styles.info__container}
        initial={'initial'}
        whileInView={'animate'}
        transition={{ duration: 0.7 }}
        variants={contactVariants}>
          <div className={styles.info__container_frame}>
            {t("How to work ?")}
          </div>
          <h1>{t("About Pro-Finansy")}</h1>
          <p>
            {t(
              "Pro-Finansy is an innovative cryptocurrency broker. We provide modern traders and investors with a platform for work that meets all quality criteria."
            )}
          </p>
          <p>
            {t(
              "Cryptocurrency broker Pro-Finansy was founded by an international financial holding company."
            )}
          </p>
          <p>
            {t(
              "Many years of experience help us to create convenient and technological services for working on the global financial markets."
            )}
          </p>
        </motion.div>
        <motion.div className={styles.info__container}
        initial={'initial'}
        whileInView={'animate'}
        transition={{ duration: 0.7 }}
        variants={contactVariants}>
          <div className={styles.info__container_frame}>
            {t("How to work ?")}
          </div>
          <h1>{t("Pro-Finansy's mission")}</h1>
          <p>
            {t(
              "Due to frequent rate changes, the cryptocurrency market has quickly become a field for traders."
            )}
          </p>
          <p>
            {t(
              "The mission of cryptocurrency broker Pro-Finansy is to become a reliable and modern crypto broker that is accessible to everyone."
            )}
          </p>
        </motion.div>
        <motion.div className={styles.info__container}
        initial={'initial'}
        whileInView={'animate'}
        transition={{ duration: 0.7 }}
        variants={contactVariants}>
          <div className={styles.info__container_frame}>
            {t("How to work ?")}
          </div>
          <h1>{t("The basis of Pro-Finansy's work")}</h1>
          <p>
            {t(
              "We have developed a functional, intuitive trading platform, which has already become a faithful assistant for beginners and experienced traders."
            )}
          </p>
          <p>
            {t(
              "We simplified the conditions for depositing and withdrawing cryptocurrency, zeroed out monthly fees and commissions for transactions."
            )}
          </p>
          <p>
            {t(
              "We gathered a team of competent and energetic traders who became round-the-clock technical support for our clients."
            )}
          </p>
          <p>
            {t(
              "Ensured server uptime and the highest level of data protection to date with the help of 27 talented technicians."
            )}
          </p>
        </motion.div>
        <motion.div className={styles.info__container}
        initial={'initial'}
        whileInView={'animate'}
        transition={{ duration: 0.7 }}
        variants={contactVariants}>
          <div className={styles.info__container_frame}>
            {t("How to work ?")}
          </div>
          <h1>{t("Pro-Finansy is open for communication")}</h1>
          <p>
            {t(
              "If you have any questions or queries about cryptocurrency broker Pro-Finansy, just write us here and we will reply to your email."
            )}
          </p>
        </motion.div>
      </div>
    </div>
  );
}
