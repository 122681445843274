import { Route, Routes } from 'react-router-dom';
import './App.css';
import { ThemeProvider, ThemeContext } from './middleware/ThemeContext.jsx';
import Home from './pages/Home/Home.jsx';
import Layout from './components/Layout.jsx';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import Login from './pages/Login/Login.jsx';
import Withdrawal from './pages/Withdrawal/Withdrawal.jsx';
import Accounts from './pages/Accounts/Accounts.jsx';
import Account from './pages/Account/Account.jsx';
import Verification from './pages/Verification/Verification.jsx';
import Orders from './pages/Orders/Orders.jsx';
import Transactions from './pages/Transactions/Transactions.jsx';
import Register from './pages/Register/Register.jsx';
import secureLocalStorage from 'react-secure-storage';
import i18n from './i18n/config.js';
import Deposit from './pages/Deposit/Deposit.jsx';
import { Alert, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MainPage from './pages/MainPage/MainPage.jsx';
import AutoLogin from './pages/AutoLogin/AutoLogin.jsx';
import LayoutLanding from './components/LayoutLanding.jsx';
import Bonuses from './pages/Bonuses/Bonuses.jsx';
import AccountTypes from './pages/AccountTypes/AccountTypes.jsx';
import Tools from './pages/Tools/Tools.jsx';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy.jsx';
import AboutCompany from './pages/AboutCompany/AboutCompany.jsx';
import AffProgram from './pages/AffProgram/AffProgram.jsx';
import Applications from './pages/Applications/Applications.jsx';

function App() {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const {t} = useTranslation();
  const [eurToUsd,setEurToUsd] = useState(0);
  const [usdToEur,setUsdToEur] = useState(0);
  const [account,setAccount] = useState(null);
  const [balance , setBalance] = useState(0)
  const [margin , setMargin] = useState(0)
  const [accounts,setAccounts] = useState([]);

  useEffect(()=>{
    const fetchEur = async ()=>{
        try{
            const {data} = await axios.get(process.env.REACT_APP_test_url + '/api/currencies/eur')
            if(data){
                setEurToUsd(data)
            }
        }
        catch(e){
            console.log(e)
        }
    }
    const fetchUsd = async ()=>{
        try{
            const {data} = await axios.get(process.env.REACT_APP_test_url + '/api/currencies/usd')
            if(data){
                setUsdToEur(data)
            }
        }
        catch(e){
            console.log(e)
        }
    }
    fetchUsd()
    fetchEur()
    if(secureLocalStorage.getItem('lang')){
      i18n.changeLanguage(secureLocalStorage.getItem('lang'));
    }
    else{
      i18n.changeLanguage("ru");
      secureLocalStorage.setItem("lang","ru");
    }
},[]);

  useEffect(()=>{
    const load = () => {
      axios.get(process.env.REACT_APP_test_url + `/api/users/${secureLocalStorage.getItem('user')?.Id}`,{
        headers:{
            'Authorization':'Bearer '+secureLocalStorage.getItem('token')
        }
      })
      .then((res)=>{
          secureLocalStorage.setItem('user',res.data.user);
          secureLocalStorage.setItem('accounts',res.data.accounts);
          setAccounts(res.data.accounts)
          secureLocalStorage.setItem('token',res.data.token);
          let account;
          if(secureLocalStorage.getItem("selected_account")){
            account = res.data.accounts.find((val)=>val?.Id == secureLocalStorage.getItem("selected_account")?.Id);
            const totalBalance = res.data.accounts.reduce((sum, val) => {
              if (val?.AccountType === "Real") {
                if (val?.AccountCurrency === "EUR") {
                  return sum + (val?.AccountBalance || 0) * usdToEur;
                } else {
                  return sum + (val?.AccountBalance || 0);
                }
              }
              return sum;
            }, 0);
            
            const totalMargin = res.data.accounts.reduce((sum, val) => {
              if (val?.AccountType === "Real") {
                if (val?.AccountCurrency === "EUR") {
                  return sum + (val?.Margin || 0) * usdToEur;
                } else {
                  return sum + (val?.Margin || 0);
                }
              }
              return sum;
            }, 0);
            setBalance(totalBalance);
            setAccount(account);
            setMargin(totalMargin);
          }
          else{
            account = res.data.accounts[0];
            const totalBalance = res.data.accounts.reduce((sum, val) => {
              if (val?.AccountType === "Real") {
                if (val?.AccountCurrency === "EUR") {
                  return sum + (val?.AccountBalance || 0) * usdToEur;
                } else {
                  return sum + (val?.AccountBalance || 0);
                }
              }
              return sum;
            }, 0);
            
            const totalMargin = res.data.accounts.reduce((sum, val) => {
              if (val?.AccountType === "Real") {
                if (val?.AccountCurrency === "EUR") {
                  return sum + (val?.Margin || 0) * usdToEur;
                } else {
                  return sum + (val?.Margin || 0);
                }
              }
              return sum;
            }, 0);
            // setBalance(totalBalance);
            setAccount(account);
            setMargin(totalMargin);
          }
          // setBalance(secureLocalStorage.getItem("selected_account")?.AccountBalance)
      })
      .catch((err)=>{
          console.log(err);
      })
    }
    load()
    if(usdToEur)
    {
      var timer = setInterval(async ()=>{
        if(secureLocalStorage.getItem('user'))
        load()
      },2000)
    }

    if(secureLocalStorage.getItem('lang')){
      i18n.changeLanguage(secureLocalStorage.getItem('lang'));
    }
    else{
      i18n.changeLanguage("en");
      secureLocalStorage.setItem("lang","en");
    }

    return ()=>{
      clearInterval(timer);
    }
  },[usdToEur])

  

const [open, setOpen] = useState(false);
const [snackMessage, setSnackMessage] = useState('');
const [snackType, setSnackType] = useState('success');

const handleClick = () => {
  setOpen(true);
};

const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setOpen(false);
};

const SuccessIcon = () => (
  <div className='success_icon'>
    <svg xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_638_53454)">
        <circle cx="8.00065" cy="8.00016" r="6.66667" stroke="#00B4A0" />
        <path d="M5.66602 8.3335L6.99935 9.66683L10.3327 6.3335" stroke="#00B4A0" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_638_53454">
          <rect width={16} height={16} fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
)

const ErrorIcon = () => (
  <div className='error_icon'>
    <svg xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
      <path d="M3.54179 7.17433C5.48737 3.72478 6.46015 2 8.00065 2C9.54115 2 10.5139 3.72477 12.4595 7.17432L12.7019 7.60418C14.3187 10.4707 15.1271 11.904 14.3965 12.952C13.6659 14 11.8583 14 8.24309 14H7.75821C4.14302 14 2.33543 14 1.60482 12.952C0.874212 11.904 1.68259 10.4707 3.29935 7.60418L3.54179 7.17433Z" stroke="#F63D68" />
      <path d="M8 5.33333V8.66666" stroke="#F63D68" strokeLinecap="round" />
      <ellipse cx="8.00065" cy="10.6667" rx="0.666667" ry="0.666667" fill="#F63D68" />
    </svg>

  </div>
)

const CloseIcon = ({onClose}) => (
  <div className='close_icon'  onClick={onClose}>
    <svg xmlns="http://www.w3.org/2000/svg" width={'1.2vh'} height={'1.2vh'} viewBox="0 0 12 12" fill="none">
      <path d="M8.24998 3.75002L3.75 8.25M3.74998 3.75L8.24996 8.24998" stroke="#A0A0AB" strokeLinecap="round" />
    </svg>
  </div>
)

  
  return (
    <div>
      <Routes>
        <Route path='/cabinet' element={<Layout theme={theme} toggleTheme={toggleTheme} 
          eurToUsd={eurToUsd}
          usdToEur={usdToEur}
          account = {account}
          setSnackMessage={setSnackMessage}
          setSnackType={setSnackType}
          handleClick={handleClick}
          setAccount = {setAccount}
          balance = {balance}
          setBalance = {setBalance}
          margin = {margin}
          setMargin = {setMargin}/>}>
          <Route path='/cabinet' element={<Home theme={theme}
          eurToUsd={eurToUsd}
          usdToEur={usdToEur}
          account = {account}
          setSnackMessage={setSnackMessage}
          setSnackType={setSnackType}
          handleClick={handleClick}
          setAccount = {setAccount}
          balance = {balance}
          setBalance = {setBalance}
          margin = {margin}
          setMargin = {setMargin}
          accounts={accounts}
          />}/>           
          <Route path='/cabinet/deposit' element={<Deposit theme={theme}
          eurToUsd={eurToUsd}
          usdToEur={usdToEur}
          account = {account}
          setSnackMessage={setSnackMessage}
          setSnackType={setSnackType}
          handleClick={handleClick}
          />}/>           
          <Route path='/cabinet/deposit/:id' element={<Deposit theme={theme}
          eurToUsd={eurToUsd}
          usdToEur={usdToEur}
          account = {account}
          setSnackMessage={setSnackMessage}
          setSnackType={setSnackType}
          handleClick={handleClick}
          />}/>          
          <Route path='/cabinet/withdrawal' element={<Withdrawal theme={theme}
          eurToUsd={eurToUsd}
          usdToEur={usdToEur}
          account = {account}
          setSnackMessage={setSnackMessage}
          setSnackType={setSnackType}
          handleClick={handleClick}
          />}/>         
          <Route path='/cabinet/withdrawal/:id' element={<Withdrawal theme={theme}
          eurToUsd={eurToUsd}
          usdToEur={usdToEur}
          account = {account}
          accounts={accounts}
          setSnackMessage={setSnackMessage}
          setSnackType={setSnackType}
          handleClick={handleClick}
          />}/>         
          <Route path='/cabinet/accounts' element={<Accounts theme={theme}
          eurToUsd={eurToUsd}
          usdToEur={usdToEur}
          accounts={accounts}
          setSnackMessage={setSnackMessage}
          setSnackType={setSnackType}
          handleClick={handleClick}
          />}/>          
          <Route path='/cabinet/orders' element={<Orders theme={theme}
          account={account}
          eurToUsd={eurToUsd}
          usdToEur={usdToEur}
          setSnackMessage={setSnackMessage}
          setSnackType={setSnackType}
          handleClick={handleClick}
          />}/> 
          <Route path='/cabinet/applications' element={<Applications theme={theme}
          account={account}
          eurToUsd={eurToUsd}
          usdToEur={usdToEur}
          setSnackMessage={setSnackMessage}
          setSnackType={setSnackType}
          handleClick={handleClick}
          />}/>                   
          <Route path='/cabinet/transactions' element={<Transactions theme={theme}
          eurToUsd={eurToUsd}
          usdToEur={usdToEur}
          account = {account}
          setSnackMessage={setSnackMessage}
          setSnackType={setSnackType}
          handleClick={handleClick}
          />}/>        
          <Route path='/cabinet/accounts/:id' element={<Account theme={theme}
          eurToUsd={eurToUsd}
          usdToEur={usdToEur}
          accounts={accounts}
          setSnackMessage={setSnackMessage}
          setSnackType={setSnackType}
          handleClick={handleClick}
          />}/>        
          <Route path='/cabinet/verification' element={<Verification theme={theme}
          eurToUsd={eurToUsd}
          usdToEur={usdToEur}
          setSnackMessage={setSnackMessage}
          setSnackType={setSnackType}
          handleClick={handleClick}
          account = {account}
          accounts={accounts}
          />}/>  
        </Route>
        <Route path='/' element={<LayoutLanding/>}>
          <Route path='/' element={<MainPage/>}></Route>
          <Route path='/bonuses' element={<Bonuses/>}></Route>
          <Route path='/about' element={<AboutCompany/>}></Route>
          <Route path='/privacy' element={<PrivacyPolicy/>}></Route>
          <Route path='/accounts' element={<AccountTypes/>}></Route>
          <Route path='/affiliate' element={<AffProgram/>} />
          <Route path='/tools' element={<Tools/>}></Route>
          <Route path='/tools/:type' element={<Tools/>}></Route>
          {/* <Route path='/tools/stockHeatMap' element={<Tools/>}></Route>
          <Route path='/tools/etfHeatMap' element={<Tools/>}></Route>
          <Route path='/tools/CryptoHeatMap' element={<Tools/>}></Route>
          <Route path='/tools/cryptocurrencyMarket' element={<Tools/>}></Route>
          <Route path='/tools/economicCalendar' element={<Tools/>}></Route>
          <Route path='/tools/topStories' element={<Tools/>}></Route> */}
        </Route>
        <Route path={'/autologin/:token/:email'} element={<AutoLogin setBalance={setBalance} setAccount={setAccount} setAccounts={setAccounts}/>}></Route>
        <Route path='/login' element={<Login setBalance={setBalance} setAccount={setAccount}/>} />
        <Route path='/register' element={<Register setBalance={setBalance} setAccount={setAccount}/>} />
      </Routes>
      <Snackbar open={open} className='snack_bar' autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <Alert icon={snackType === 'success'?<SuccessIcon/>:<ErrorIcon/>} className='snack_alert' action={<CloseIcon  onClose={handleClose}/>}  onClose={handleClose} severity={snackType} sx={{ width: '100%'}}>
          <h3 className='snack_title'>{snackType === 'success'?t('Done!'):t('Error!')}</h3>
          <p className='snack_text'> {snackMessage}</p>
        
        </Alert>
      </Snackbar>
    </div>
  );
}

const Root = () => (
  <ThemeProvider>
    <App />
  </ThemeProvider>
);
export default Root;
